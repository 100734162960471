export const CARRENTAL_FLAGS = {
  exportVehicleList: 'msbo-digital-station-export-vehicle-list',
  manageZones: 'msbo-digital-station-manage-zones',
  vehicleTelemetry: 'msbo-digital-station-vehicle-telemetry-tab',
  vehicleTripsTab: 'msbo-digital-station-vehicle-trips-tab',
  importAgents: 'msbo-digital-station-import-agents',
  collisionReport: 'msbo-digital-station-collision-report',
  devicesBulkActivation: 'msbo-digital-station-devices-bulk-activation',
  camelCasedFiltersEnabled: 'msbo-digital-station-camelcase-export-vehicle-list',
};
